import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'lsu-layout-code',
  styleUrls: ['./code.component.scss'],
  templateUrl: './code.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeComponent {}
